<template>
  <div class="complaint-detail">

    <!-- 自定义头部 -->
    <div class="comp-navbar">
      <!-- <img  class="header-bg" src="@/assets/yq/header-bg.png"></img> -->
      <!-- 占位栏 -->
      <div class="placeholder-bar" :style="{ height: navBarHeight + 'px' }">
        <img class="header-bg" src="@/assets/yq/index-header-bg-02.png" />
        <div class="bar-nav" :style="{ height: navBarHeight + 'px' }">
          <!-- <div class="nav-statusbar" :style="{ height: statusBarHeight + 'px' }">
          </div> -->
          <div class="nav-titlebar" :style="{
                            height: titleBarHeight + 'px',
                        }">
            <img @click="backClick" class="img" src="@/assets/yq/back.png" />
            <div class="text">
              合同详情
            </div>
          </div>
        </div>
        <div class="part-circle"> </div>
      </div>
    </div>

    <div class="complaint-card bg-fff flex">
      <div class="text">
        <div class="number">
          合同编号 {{detailObj.contractCode}}
        </div>
        <div class="title-box flex">
          <img src="@/assets/yq/contract-03.png">
          <div>{{detailObj.contractName}}</div>
        </div>
      </div>
      <div class="status-box">
        <div class="wait-sign" v-if="detailObj.contractStatus==0">
          <img src="@/assets/yq/wait-sign-status.png">
          <div>待签署</div>
        </div>
        <div class="no-wait" v-else>
            <img :src="require('@/assets/yq/contract-status-'+detailObj.contractStatus+'.png')"
                v-if="detailObj.contractStatus"
            >
        </div>

      </div>
    </div>
    <div class="complaint-process bg-fff flex">
      <div class="step-new">
        <div class="step-item flex" v-if="detailObj.status==2">
          <div class="step-line">
            <div class="step-ico"></div>
            <div class="line"></div>
          </div>
          <div class="text">
            <div class="status-text" :style="{color:(detailObj.status==2?'#4B5EB4':'#DFE0E2')}">已处理</div>
            <div class="content" :style="{color:(detailObj.status==2?'#575759':'#B0B0B0')}">
              工单结束
            </div>
            <div class="time">
              {{detailObj.solveTime}}
            </div>
          </div>
        </div>
        <div class="step-item flex">
          <div class="step-line">
            <div class="step-ico"></div>
            <div class="line"></div>
          </div>
          <div class="text">
            <div class="status-text" :style="{color:(detailObj.status==1?'#4B5EB4':'#DFE0E2')}">
                处理中
            </div>
            <div class="content" :style="{color:(detailObj.status==1?'#575759':'#B0B0B0')}">
                客服中心正在处理，
                请您耐心等候。感谢您对建管家的支持，祝您
                生活愉快!
            </div>
            <div class="time">
                {{detailObj.sendTime}}
            </div>

          </div>
        </div>
        <div class="step-item flex">
          <div class="step-line">
            <div class="step-ico"></div>
            <div class="line"></div>
          </div>
          <div class="text">
            <div class="status-text" :style="{color:'#DFE0E2'}">
                已提交
            </div>
            <div class="content" :style="{color:'#DFE0E2'}">
                {{detailObj.content}}
            </div>
            <div class="time">
                {{detailObj.sendTime}}
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
//   import complaintConfirm from "@/components/mine/complaintConfirm.vue";
  import { getComplaintDetails } from "@/api/complaint";
  export default {
    components: {
    //   complaintConfirm
    },
    data() {
      return {
        id: "", //每条新闻资讯的id
        headerTitle: "投诉详情",
        verticalCurrent: 0,
        modal: false,
        modal2: false,
        detailObj: {},
        statusText: [],
        statusBarHeight: 44, // 状态栏高度
        titleBarHeight: 48, // 标题栏高度
        navBarHeight: 124, // 导航栏总高度
      };
    },
    beforeMount() {
      const self = this;
      self.navBarHeight = self.statusBarHeight + self.titleBarHeight;
    },
    methods: {
        backClick() {

            this.$router.go(-1);

        },
    },
    created() {
      //this.$root.$mp.query
        let id = this.$route.query.id;
        this.id = id;
        console.log(id);
        getComplaintDetails({id}).then(res => {
            let { status } = res;
            if (status) {
                this.detailObj = res.data.complaintInfo;
            }
        })
    }
  };
</script>

<style>

  div {
    box-sizing: border-box;
  }
  /* 头部 */
  .comp-navbar {
    width: 100vw;
    background: transparent;
  }

  .header-bg {
    width: 100%;
    height: 124px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .bar-nav {
    position: relative;
  }

  .nav-titlebar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

  }

  .nav-titlebar .img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    margin: auto;
    width: 10px;
    height: 18px;
  }

  .nav-titlebar .text {
    font-size: 16px;
    color: #fff;
  }

  .nav-titlebar .img img {
    width: 10px;
    height: 18px;
  }


  .complaint-card {
    position: relative;
    width: 351px;
    height: 105px;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: -30px;
    padding: 26px;
    justify-content: space-between;
  }

  .complaint-card .text {
    width: 280px;
    color: #999999;
    font-size: 14px;
  }

  .complaint-card .text .title-box {
    margin-top: 19.5px;
    height: 26px;
    align-items: center;
  }

  .complaint-card .text .title-box img {
    width: 30px;
    height: 26px;
  }

  .status-box {
    position: absolute;
    top: 0;
    right: 0;
  }

  .complaint-card .status-box .wait-sign {
    width: 59px;
    height: 21.5px;
    position: relative;
  }

  .complaint-card .wait-sign img {
    width: 59px;
    height: 21.5px;
  }

  .complaint-card .wait-sign div {
    font-size: 11px;
    font-weight: 500;
    color: #B87942;
    position: absolute;
    top: 2.5px;
    right: 10px;
    z-index: 10;
  }

  .complaint-card .status-box .no-wait img {
    width: 99px;
    height: 67px;
  }

  .complaint-card .text .title-box div {
    margin-left: 10px;
    font-size: 18px;
    font-family: Noto Sans S Chinese;
    font-weight: 500;
    color: #222222;
  }

  .complaint-card .img img {
    width: 60px;
    height: 60px;
  }

  .complaint-process {
    /* height: 100vh; */
    width: 351px;
    margin: 0 auto;
    margin-top: 12px;
    padding: 29px 20px 0 20px;
  }

  .complaint-process .step .step-item {
    padding-bottom: 30px;
    /* padding-left: 23px; */
  }

  .step .step-item .step-text {
    margin-left: 23px;
  }

  .complaint-process .step .step-item .title {
    color: #999999;
    position: relative;
    height: 24px;
    line-height: 24px;
  }

  .complaint-process .step .step-item .title,
  .content {
    font-size: 16px;
    font-family: Noto Sans S Chinese;
    font-weight: 400;
  }

  .complaint-process .step .step-item .content {
    margin-top: 13px;
  }

  .step .step-item .item-ico {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-top: 5px; */
  }

  .step-new {
    /* margin-top: 20px; */
    padding-bottom: 30px;
  }

  .step-new .step-item {
    padding-bottom: 25px;
    /* border-left: 1px dashed #E8E8E8; */
    position: relative;
  }

  .step-new .step-item .step-line {
    width: 20px;
    height: 10px;
    display: flex;
    align-items: center;
    background: #fff;
    overflow: hidden;

  }

  .step-new .step-line .step-ico {
    width: 10px;
    height: 10px;
    background: #E8E8E8;
    border-radius: 50%;
    position: relative;
    z-index: 2;
  }

  .step-new .step-line .line {
    background: #E8E8E8;
    position: absolute;
    height: 100%;
    top: 0;
    left: -7.5px;
    margin: 0 0 0 12px;
    width: 1px;
  }

  .step-new .step-item .text {
    font-size: 13px;
    /* margin-left: 2px; */
  }

  .step-new .step-item .text .status-text {
    font-size: 18px;
    color: #DFE0E2;
    font-family: PingFang SC;
    font-weight: 500;
    padding: 0;
  }

  .step-new .step-item .text .title {
    margin-top: 10px;
    height: 18px;
    line-height: 10px;
    color: #B0B0B0;
    font-size: 13px;
  }

  .step-new .step-item .text .content {
    margin-top: 10px;
    width: 270px;
    line-height: 20px;
    font-size: 14px;
    color: #B0B0B0;
  }

  .step-new .step-item .text .time {
    font-size: 13px;
    font-weight: 300;
    color: #B0B0B0;
    margin-top: 10px;
  }
</style>
